import { css } from '@emotion/react'
import type { EndorsementDetails } from '@orus.eu/backend/src/services/subscription/endorsement-service'
import { type CalendarDate } from '@orus.eu/calendar-date'
import { TechnicalError } from '@orus.eu/error'
import {
  AvatarDecorative,
  Button,
  CheckboxContainer,
  Datepicker,
  HorizontalLogo,
  Text,
  colors,
  spacing,
  useAsyncCallback,
  useCrash,
  useTranslate,
} from '@orus.eu/pharaoh'
import { ContractBannerV2, useUrlFileBytes } from '@orus.eu/pharaoh/src/components/features/contract-signing'
import {
  SignEndorsementLayout,
  SignEndorsementSuccessLayout,
} from '@orus.eu/pharaoh/src/components/features/endorsement'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useCallback, useEffect, useState } from 'react'
import { trpc, trpcReact } from '../../client'
import { useTimedExplanations } from '../../lib/hooks/use-timed-explanations'
import { GlobalLoadingState } from '../molecules/global-loading-state'

const SignEndorsementPage: React.FunctionComponent = memo(function SignEndorsementPage(): JSX.Element {
  const [endorsementDetails, setEndorsementDetails] = useState<EndorsementDetails>()
  const { token } = useParams({ from: '/s/e/$token' })
  const [effectDate, setEffectDate] = useState<CalendarDate>()
  const crash = useCrash()
  const navigate = useNavigate()

  const handleEffectDateChange = useCallback((date: CalendarDate | undefined) => {
    setEffectDate(date)
  }, [])

  useEffect(() => {
    let cancelled = false

    ;(async () => {
      const endorsementDetailsResults = await trpc.endorsement.receiveEndorsement.mutate({ token, effectDate })
      setEndorsementDetails(endorsementDetailsResults)
    })().catch((_err: unknown) => {
      if (cancelled) return
      void navigate({ to: '/' })
    })

    return () => {
      cancelled = true
    }
  }, [crash, navigate, token, effectDate])

  if (endorsementDetails && token) {
    return (
      <SignEndorsementLayout>
        <SignEndorsementPageContent
          token={token}
          endorsementDetails={endorsementDetails}
          onEffectDateChange={handleEffectDateChange}
        />
      </SignEndorsementLayout>
    )
  } else {
    return <PrepareEndorsementLoadingState />
  }
})

type SignEndorsementPageContentProps = {
  endorsementDetails: EndorsementDetails
  token: string
  onEffectDateChange: (date: CalendarDate | undefined) => void
}

const SignEndorsementPageContent: React.FunctionComponent<SignEndorsementPageContentProps> = memo(
  function SignEndorsementPageContent(props): JSX.Element {
    const translate = useTranslate()
    const { endorsementDetails, token, onEffectDateChange } = props
    const {
      contractFileId,
      quote,
      productsAttributes,
      yearlyCommitmentHasDiscount,
      commitment,
      forbiddenMonthlyPayment,
      discount,
      historyTakeoverPrice,
      minStartDate,
      partnerApplicationFee,
    } = endorsementDetails
    const [acceptContractButtonEnabled, setAcceptContractButtonEnabled] = useState<boolean>(false)
    const [draftContractFile, setDraftContractFile] = useState<Uint8Array>()
    const { data: contractFile } = useUrlFileBytes(`/download/${contractFileId}/`, { autoLoad: true })
    const draftAgreedTermQuery = trpcReact.endorsement.downloadDraftAgreedTerms.useQuery({ token })
    const [currentStartDate, setCurrentStartDate] = useState<CalendarDate>(minStartDate)
    const [endorsementSigned, setEndorsementSigned] = useState<boolean>(false)
    const [signInProgress, setSignInProgress] = useState<boolean>(false)

    useEffect(() => {
      if (draftAgreedTermQuery.data && !draftContractFile) {
        setDraftContractFile(new Uint8Array(draftAgreedTermQuery.data))
      }
    }, [draftAgreedTermQuery, draftContractFile])

    const signEndorsement = useAsyncCallback(async () => {
      setSignInProgress(true)
      const result = await trpc.endorsement.signEndorsement.mutate({
        signableFileId: contractFileId,
        startEffectDate: currentStartDate,
        token,
      })
      if (result.type === 'failure') {
        throw new TechnicalError('Unhandled signEndorsement failure', { context: { problem: result.problem } })
      }
      setEndorsementSigned(true)
      setSignInProgress(false)
    }, [contractFileId, currentStartDate, token])

    const handleCurrentStartDateChange = useCallback(
      (date: CalendarDate | null) => {
        if (date) setCurrentStartDate(date)
        onEffectDateChange(date ?? undefined)
      },
      [onEffectDateChange],
    )

    const handleSetAcceptContractChange = useCallback((newValue: boolean) => {
      setAcceptContractButtonEnabled(newValue)
    }, [])

    if (endorsementSigned) {
      return <SignEndorsementSuccess />
    }

    return (
      <>
        <HorizontalLogo color={colors.blue[900]} height={22} />
        <Text variant="h4">Signer votre avenant</Text>
        <ContractBannerV2
          monthlyPrice={quote.subsequentMonthsTotalPremium}
          yearlyPrice={quote.yearlyTotalPremium}
          paymentRecurrence={quote.paymentRecurrence}
          forbiddenMonthlyPayment={forbiddenMonthlyPayment}
          commitment={commitment}
          yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
          discount={discount}
          firstPeriodPrice={quote.firstPaymentAmount}
          yearlyTerrorismTaxPrice={quote.terrorismTax}
          productsAttributes={productsAttributes}
          downloadablePdfBytes={draftContractFile}
          pdfBytes={contractFile}
          historyTakeoverPrice={historyTakeoverPrice}
          partnerApplicationFee={partnerApplicationFee}
        />

        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Text variant="subtitle2" element="label">
            Date d&apos;effet de l&apos;avenant
          </Text>

          <Datepicker
            onChange={handleCurrentStartDateChange}
            calendarDate={currentStartDate}
            minDate={minStartDate}
            css={css`
              margin-top: ${spacing[50]};
            `}
          />
        </div>

        <div>
          <CheckboxContainer
            checked={acceptContractButtonEnabled}
            onChange={handleSetAcceptContractChange}
            border={true}
          >
            <Text variant="body2">{translate('sign_endorsement_page_accept_contract_declaration')}</Text>
          </CheckboxContainer>
        </div>

        <Button
          css={css`
            margin-left: auto;
          `}
          disabled={!acceptContractButtonEnabled || signInProgress}
          onClick={signEndorsement}
        >
          Signer
        </Button>
      </>
    )
  },
)

const SignEndorsementSuccess: React.FunctionComponent = memo(function SignEndorsementSuccess(): JSX.Element {
  const navigate = useNavigate()
  const translate = useTranslate()
  const goToHomePage = useCallback(() => {
    void navigate({ to: '/' })
  }, [navigate])

  return (
    <SignEndorsementSuccessLayout>
      <HorizontalLogo
        css={css`
          margin-right: auto;
        `}
        color={colors.blue[900]}
        height={22}
      />
      <AvatarDecorative size="80" icon="check-solid" backgroundColor="mindaro" />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <Text variant="h4">Avenant signé !</Text>
        <Text
          css={css`
            margin-top: ${spacing[40]};
          `}
          variant="body2"
        >
          {translate('sign_endorsement_page_accept_contract_declaration_success')}
        </Text>
      </div>
      <Button variant="primary" size="medium" onClick={goToHomePage}>
        Je me connecte
      </Button>
    </SignEndorsementSuccessLayout>
  )
})

const PrepareEndorsementLoadingState = memo(function SigningLoadingState() {
  const currentExplanation = useTimedExplanations('sign_endorsement_loading_explanations', 3000)

  return <GlobalLoadingState text={currentExplanation} />
})

export default SignEndorsementPage
